import {createSlice} from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        zip: null,
        authenticated: false,
        low: null,
        high: null,
    },
    reducers: {
        login: (state, action) => {
            state.zip = action.payload.zip;
            state.low = action.payload.low;
            state.high = action.payload.high;
            state.authenticated = action.payload.authenticated;
            state.pro = action.payload.pro;
        },
        logout: (state) => {
            state.name = null;
            state.zip = null;
            state.authenticated = false;
            state.low = null;
            state.high = null;
            state.pro = false;
        },
    }
});

export const { login, logout, authenticated } = userSlice.actions;

export default userSlice.reducer;

