import { useEffect, useState } from "react";
import ShippingUPSBox from "./ShippingUPSBox";
import ShippingBoxUSPS from "./ShippingUSPS";

const Results = (props) => {

  const [ups, setUPS] = useState([]);

  const trimUPS = () => {
    let upsTrimmed = [];
    for (let i = 0; i < props.ups.length; i++) {
      if (props.ups[i].Service.Code === "1DA") {
        upsTrimmed.push(props.ups[i]);
      } else if (props.ups[i].Service.Code === "GND") {
        upsTrimmed.push(props.ups[i]);
      } else if (props.ups[i].Service.Code === "2DA") {
        upsTrimmed.push(props.ups[i]);
      } else if (props.ups[i].Service.Code === "3DS") {
        upsTrimmed.push(props.ups[i]);
      }
    }
    setUPS(upsTrimmed);
  };

  useEffect(() => {
    trimUPS();
  }, []);

  return (
    <>
      <div className=" flex justify-start md:justify-center content-center bg-slate-100 text-center space-x-4 overflow-x-auto max-w-full max-h-max mb-10 ">
        {props.services.map((service, i) => {
          return (
            <ShippingBoxUSPS
              key={i}
              service={service.service}
              expectedDelivery={service.deliveryDate}
              weather={props.weather}
              low={props.low}
              high={props.high}
              tempUnit = {props.tempUnit}
            />
          );
        })}
      </div>
      <div className=" flex justify-start content-center bg-slate-100 text-center space-x-4 overflow-x-auto max-w-full max-h-max mb-20 ">
        {ups.map((service, i) => {
          return (
            <ShippingUPSBox
              key={i}
              service={service.Service.Description}
              expectedDelivery={service.EstimatedArrival.Arrival.Date}
              weather={props.weather}
              low={props.low}
              high={props.high}
              tempUnit = {props.tempUnit}

            />
          );
        })}
      </div>
    </>
  );
};

export default Results;
