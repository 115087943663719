import DayForecast from "./DayForecast";

const Forecast = (props) => {
  let upsTrimmed = [];
  for (let i = 0; i < props.ups.length; i++) {
    if (props.ups[i].Service.Code === "1DA") {
      upsTrimmed.push(props.ups[i]);
    } else if (props.ups[i].Service.Code === "GND") {
      upsTrimmed.push(props.ups[i]);
    } else if (props.ups[i].Service.Code === "2DA") {
      upsTrimmed.push(props.ups[i]);
    } else if (props.ups[i].Service.Code === "3DS") {
      upsTrimmed.push(props.ups[i]);
    }
  }

  return (
    <div className="max-w-full max-h-max md:mx-0 overflow-x-auto bg-white mx-10 mb-10 ">
      <div className="flex items-center space-x-5  p-10 pb-12 rounded-2xl justify-start md:justify-center ">
        {props.weather.map((day, i) => {
          let date = new Date();
          date.setDate(date.getDate() + i);

          return (
            <DayForecast
              high={day.temp.max}
              low={day.temp.min}
              conditions={day.weather[0].main}
              icon={day.weather[0].icon}
              day={i}
              services={props.services}
              key={i}
              date={date}
              user={props.user}
              ups={upsTrimmed}
              tempUnit = {props.tempUnit}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Forecast;
