import { Link, useNavigate } from "react-router-dom";
import UserBar from "../Components/UserBar";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../app/profile";
import { useState } from "react";
import LoadingReact from "../assets/LoadingReact";

const LoginPage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const email = e.target.email.value.toLowerCase();
    const password = e.target.password.value;

    const graphqlQuery = {
      query: `
                query {
                    login(email: "${email}", password: "${password}") {
                        userId
                        token
                        zip
                        email
                        low
                        high
                        membership
                        pro
                    }
                }
                `,
    };

    fetch(`${process.env.REACT_APP_BACKENDSERVER}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          res.json().then((resData) => {
            throw new Error(resData.errors[0].message);
          });
        } else {
          return res.json();
        }
      })
      .then((loginData) => {
        localStorage.setItem("token", loginData.data.login.token);
        localStorage.setItem("userId", loginData.data.login.userId);
        const expiration = Date.now() + 7200000;
        localStorage.setItem("expiration", expiration);
        dispatch(
          login({
            zip: loginData.data.login.zip,
            low: loginData.data.login.low,
            high: loginData.data.login.high,
            authenticated: loginData.data.login.membership,
            pro: loginData.data.login.pro,
          })
        );
        navigate("/");
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <UserBar />
      <div className="flex font-sans flex-col justify-center items-center w-screen bg-slate-50 min-h-screen text-center">
        <div className="my-5">
          <h1 className="text-5xl font-bold text-slate-800">Hello Again,</h1>
          <p className="text-md py-5 font-medium text-slate-700 ">
            Sign in below or{" "}
            <Link to={"/register"} className="text-sky-600">
              click here{" "}
            </Link>{" "}
            to create your free account.
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col bg-white py-5 px-10 rounded-2xl"
        >
          {isLoading ? (
            <LoadingReact />
          ) : (
            <>
              {error.length > 0 && (
                <h3 className="text-red-700 font-semibold py-4">
                  Uh oh! Looks like one of your login details is incorrect!
                </h3>
              )}
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="email"
              >
                Email
              </label>

              <input
                id="email"
                className="md:w-96 p-3 my-3 rounded-lg border"
                type="text"
                placeholder="Email address"
                required
              />
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="password "
              >
                Password
              </label>
              <input
                id="password"
                className="md:w-full p-3 my-3 rounded-lg  border w-80"
                type="password"
                placeholder="Password"
                required
              />
              <button
                className="p-4 my-3 shadow-md bg-sky-500 text-slate-50 font-semibold rounded-lg hover:bg-sky-400"
                type="submit"
              >
                Login
              </button>
              <h3 className="text-md text-slate-700">
                Forgot your password? Click here.
              </h3>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default LoginPage;
