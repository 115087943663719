import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import hamburger from "../assets/hamburger.svg";
import logo from "../assets/weatheriship.svg";
const UserBar = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const clickHandler = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showMenu]);

  const clickHandlerDiv = () => {
    setShowMenu(false);
  };

  const userredux = useSelector((state) => state.user);

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("expiration") > Date.now()
    ) {
      setUser(userredux);
    } else {
      setUser(userredux);
    }
  }, []);

  const navigateHandler = () => {
    navigate("/subscriptions");
  };

  return (
    <div className={showMenu ? "" : ""}>
      {!userredux.authenticated && (
        <div className="md:h-10 bg-sky-700 flex items-center justify-center h-12">
          <h3 className="text-white font-medium text-sm px-10 text-center">
            Upgrade membership to access UPS data, future ship dates, and more!
          </h3>
        </div>
      )}

      {userredux.authenticated && !userredux.pro && (
        <div className="md:h-10 bg-sky-700 flex items-center justify-center h-12">
          <h3 className="text-white font-medium text-sm px-10 text-center">
            Ship multiple things at once? Upgrade to Pro to access our bulk
            shipping feature!
          </h3>
        </div>
      )}

      <div
        onClick={clickHandlerDiv}
        className={
          "md:hidden  transition-all duration-300 ease-in-out absolute top-0 right-0 w-screen h-screen overflow-hidden " +
          (showMenu ? " bg-slate-500/40 z-10" : " bg-slate-500/0 -z-10")
        }
      >
        <div
          className={
            "flex absolute  flex-col  h-full w-3/5 bg-white transition-all duration-500" +
            (showMenu ? " right-0" : " -right-3/4")
          }
        >
          <Link to="/">
            <h3 className="cursor-pointer mx-10 my-3 font-medium text-2xl text-slate-700 hover:text-slate-900 mt-10">
              Home
            </h3>
          </Link>
          {userredux.pro && (
            <Link to="/bulk-upload">
              <h3 className="cursor-pointer mx-10 my-3 font-medium text-2xl text-slate-700 hover:text-slate-900">
                Bulk Upload
              </h3>
            </Link>
          )}
          {userredux ? (
            <Link to="/dashboard">
              <h3 className="cursor-pointer mx-10 my-3 font-medium text-2xl text-slate-700 hover:text-slate-900">
                Dashboard
              </h3>
            </Link>
          ) : (
            <Link to="/login">
              <h3 className="cursor-pointer mx-8 my-3 font-medium text-2xl text-slate-700 hover:text-slate-900">
                Login
              </h3>
            </Link>
          )}
          <hr className="mt-2"></hr>
          {(!userredux.authenticated || !userredux.pro || !userredux) && (
            <button
              onClick={navigateHandler}
              className="md:block hidden px-6 md:px-4 py-2 md:py-3 mr-4 md:ml-6 shadow-md bg-sky-600 text-slate-50 text-md font-semibold rounded-xl hover:bg-sky-500"
            >
              Subscriptions
            </button>
          )}
        </div>
      </div>

      <div className="md:absolute relative flex justify-end items-center w-full my-5">
        <div className="relative justify-start items-center flex w-full ">
          <Link to="/">
            <img src={logo} className="h-14 px-4 cursor" />
          </Link>
        </div>

        <img
          src={hamburger}
          alt="hamburger"
          className="md:hidden cursor-pointer h-8 mr-5"
          onClick={clickHandler}
        />
        <Link to="/">
          <h3 className="md:block hidden cursor-pointer mx-4 my-3 font-medium text-md text-slate-700 hover:text-slate-900 text-lg">
            Home
          </h3>
        </Link>
        {userredux.pro && (
          <Link to="/bulk-upload">
            <h3 className=" md:block hidden cursor-pointer mx-4 my-3 font-medium text-md text-slate-700 hover:text-slate-900 text-lg">
              Bulk Upload
            </h3>
          </Link>
        )}
        {userredux.zip ? (
          <Link to="/dashboard">
            <h3 className="md:block hidden  cursor-pointer mx-4 my-3 font-medium text-md text-slate-700 hover:text-slate-900 text-lg">
              Dashboard
            </h3>
          </Link>
        ) : (
          <Link to="/login">
            <h3 className="md:block hidden cursor-pointer mx-4 my-3 font-medium text-md text-slate-700 hover:bg-sky-700 text-lg bg-sky-800 py-2 px-4 rounded-xl text-white">
              Login
            </h3>
          </Link>
        )}

        {/* {(!userredux.authenticated || !userredux.pro || !userredux) && (
          <button
            onClick={navigateHandler}
            className=" md:block hidden px-6 md:px-4 py-2 md:py-3 mr-4 md:ml-6 shadow-md bg-sky-600 text-slate-50 text-md font-semibold rounded-xl hover:bg-sky-500"
          >
            Upgrade Membership
          </button>
        )} */}
      </div>
    </div>
  );
};

export default UserBar;

// const fetchUser = async () => {
//   const graphqlQuery = {
//     query: `
//               query {
//                   user {
//                       _id
//                       membership
//                   }
//               }
//               `,
//   };

//   const res = await fetch(`${process.env.REACT_APP_BACKENDSERVER}/graphql`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + localStorage.getItem("token"),
//     },

//     body: JSON.stringify(graphqlQuery),
//   });

//   const data = await res.json();

//   if (data.data.user.membership) {
//     setUserInfo(true);
//   }

//   if (data.errors) {
//     console.log(data.errors);
//     localStorage.removeItem("token");
//     localStorage.removeItem("userId");
//     localStorage.removeItem("expiration");
//     navigate("/login");
//   }
// };
