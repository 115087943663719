import { useState } from "react";
import envelope from "../assets/envelope.svg";

const Help = () => {
  const [open, setOpen] = useState(false);

  const contactUsEnvelope = () => {
    setOpen(!open);
  };

  return (
    <div
      onClick={contactUsEnvelope}
      className="md:fixed md:h-12 bg-sky-600 min-width-max rounded-2xl flex justify-center items-center md:bottom-10 md:right-10 hover:bg-sky-500 cursor-pointer transition transition-all duration-300 m-5"
    >
      {!open && <img src={envelope} alt="envelope" className="h-8 w-8 mx-4" />}
      {open && (
        <p className="mx-5 text-slate-100 ">
            <span className=" font-normal">
            Questions? Bugs? Concerns? Please email us at 
            </span>
           <a className="font-semibold" href="mailto:help@weatheriship.com"> help@weatheriship.com</a>   
        </p>)}
    </div>
  );
};

export default Help;
