import { useEffect, useState } from "react";

import express from "../assets/uspsexpress.svg";
import priority from "../assets/uspspriority.svg";
import first from "../assets/groundadvantage.svg";
import upslogo from "../assets/ups.svg";

const DayForecast = (props) => {
  const today = new Date();
  const [matched, setMatched] = useState(false);

  const [service, setService] = useState([]);

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const day = weekday[today.getDay() + props.day];

  const membership = props.user.membership;

  const services = props.services;

  const ups = props.ups;

  const [upsService, setUpsService] = useState([]);

  useEffect(() => {
    setMatched();
    setService([]);
    if (services.length > 0) {
      services.map((service) => {
        const date = new Date(service.deliveryDate);
        date.setDate(date.getDate() + 1);
        if (date.getDate() === props.date.getDate()) {
          setMatched(true);
          setService((prev) => [...prev, service.service]);
        }
      });

      if (ups.length > 0) {
        ups.map((service) => {
          const dateString = service.EstimatedArrival.Arrival.Date;
          const year = dateString.substring(0, 4);
          const month = dateString.substring(4, 6);
          const day = dateString.substring(6, 8);

          const date = new Date(year, month - 1, day);

          if (date.getDate() === props.date.getDate()) {
            setUpsService((prev) => [...prev, service.Service.Description]);
          }
        });
      }
    }
  }, [props.services]);

  return (
    <div className="flex flex-col items-center relative">
      <div className="flex absolute top-[-2.5rem] items-center h-10">
        {service.includes("Priority Mail Express") && (
          <img className="w-14 " src={express} />
        )}

        {service.includes("Priority Mail") && (
          <img className="w-14  " src={priority} />
        )}
        {service.includes("First-Class Mail") && (
          <img className="w-14" src={first} />
        )}
      </div>
      <div
        className={`min-w-max flex flex-col justify-center items-center border border-4 p-2 border-white 
      ${
        matched &&
        service.includes("Priority Mail") &&
        `border-4 border !border-indigo-300 p-2`
      }

      ${
        matched &&
        service.includes("First-Class Mail") &&
        `border-4 border !border-indigo-100 p-2`
      } ${
          matched &&
          service.includes("Priority Mail Express") &&
          `border-4 border !border-indigo-400 p-2 `
        }
        `}
      >
        <h2 className="text-2xl font-bold text-sky-900">{day}</h2>
        <img
          className="m-2"
          src={`https://openweathermap.org/img/wn/${props.icon}.png`}
          alt="weather icon"
        />
        <h3 className="text-sky-900 m-1 font-semibold opacity-75 text-lg">
          High:{" "}
          {props.tempUnit ? (
            <span>{Math.round((props.high * 10) / 10)} °F</span>
          ) : (
            <span>{Math.round(((props.high - 32) * 5) / 9)} °C</span>
          )}
        </h3>
        <h3 className="text-sky-900 font-semibold opacity-75 text-lg">
          Low:{" "}
          {props.tempUnit ? (
            <span>{Math.round((props.low * 10) / 10)} °F</span>
          ) : (
            <span>{Math.round(((props.low - 32) * 5) / 9)} °C</span>
          )}
        </h3>
      </div>
      <div className="flex absolute bottom-[-2.6rem] items-center h-10">
        {membership && upsService.includes("UPS Next Day Air") && (
          <div className="flex items-center">
            <img className="h-8" src={upslogo} />
            <p className=" ml-2 italic font-semibold text-sm tracking-wide antialiased">
              Next Day Air
            </p>
          </div>
        )}
        {membership && upsService.includes("UPS 2nd Day Air") && (
          <div className="flex items-center">
            <img className="h-8" src={upslogo} />
            <p className=" ml-2  italic font-semibold text-sm tracking-wide antialiased">
              2nd Day Air
            </p>
          </div>
        )}
        {membership && upsService.includes("UPS 3 Day Select") && (
          <div className="flex items-center">
            <img className="h-8" src={upslogo} />
            <p className="ml-2  italic font-semibold text-sm tracking-wide antialiased">
              3 Day Select
            </p>
          </div>
        )}
        {membership && upsService.includes("UPS Ground") && (
          <div className="flex items-center">
            <img className="h-8" src={upslogo} />
            <p className=" ml-2 italic font-semibold text-sm tracking-wide antialiased">
              Ground
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DayForecast;
