import { Link, useNavigate } from "react-router-dom";
import UserBar from "../Components/UserBar";
import { login } from "../app/profile";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import LoadingReact from "../assets/LoadingReact";
const SignupPagePro = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value.toLowerCase();
    const password = e.target.password.value;
    const confirmpassword = e.target.confirmpassword.value;
    const ZIP = e.target.ZIP.value;

    if (password !== confirmpassword) {
      alert("Passwords do not match");
      return;
    }

    const graphqlQuery = {
      query: `
                mutation {
                    createUser(userInput: {email: "${email}", password: "${password}", zip: "${ZIP}"}) {
                        _id
                    }
                }
                `,
    };

    fetch(`${process.env.REACT_APP_BACKENDSERVER}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Failed");
        }
        return res.json();
      })
      .then((resData) => {
        const graphqlQuery = {
          query: `
                    query {
                        login(email: "${email}", password: "${password}") {
                            userId
                            token
                            zip
                            email
                            low
                            high
                            membership
                        }
                    }
                    `,
        };

        fetch(`${process.env.REACT_APP_BACKENDSERVER}/graphql`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              throw new Error("Failed");
            }
            return res.json();
          })
          .then((loginData) => {
            localStorage.setItem("token", loginData.data.login.token);
            localStorage.setItem("userId", loginData.data.login.userId);
            const expiration = Date.now() + 7200000;
            localStorage.setItem("expiration", expiration);
            dispatch(
              login({
                zip: loginData.data.login.zip,
                low: loginData.data.login.low,
                high: loginData.data.login.high,
                authenticated: loginData.data.login.membership,
              })
            );
          })
          .then(() => {
            upgradeMembership();
          });
      });
  };

  const upgradeMembership = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKENDSERVER}/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          priceId: "price_1Me258JwBvsDAd36LHVXFKD5",
        }),
      }
    );

    const data = await res.json();

    window.location.href = data.url;
  };

  return (
    <>
      <UserBar />
      <div className="flex font-sans flex-col justify-center items-center w-screen bg-slate-50 min-h-screen text-center">
        <div className="my-5">
          <h1 className="text-5xl font-bold text-slate-800">Create account</h1>
          <p className="text-md py-5 font-medium text-slate-700 ">
            Already have an account? {""}
            <Link to={"/login"} className="text-sky-600">
              Click here to sign in.
            </Link>
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col bg-white py-5 px-10 rounded-2xl"
        >
          {isLoading ? (
            <LoadingReact />
          ) : (
            <>
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="email"
              >
                Email
              </label>

              <input
                required
                id="email"
                className="md:w-96 p-3 my-3 rounded-lg border"
                type="text"
                placeholder="Email address"
              />
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="ZIP "
              >
                Zip Code
              </label>
              <input
                required
                id="ZIP"
                className="w-full p-3 my-3 rounded-lg  border"
                maxLength={5}
                minLength={5}
                type="text"
                placeholder="Zip Code"
              />

              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="password "
              >
                Password
              </label>
              <input
                required
                id="password"
                className="w-full p-3 my-3 rounded-lg  border"
                type="password"
                minLength={6}
                placeholder="Password"
              />
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="confirmpassword "
              >
                Confirm Password
              </label>
              <input
                required
                id="confirmpassword"
                className="w-full p-3 my-3 rounded-lg  border"
                type="password"
                placeholder="Confirm Password"
              />

              <button
                className="p-4 my-3 shadow-md bg-sky-500 text-slate-50 font-semibold rounded-lg hover:bg-sky-400"
                type="submit"
              >
                Next
              </button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default SignupPagePro;
