import { useState } from "react";

const BulkResults = (props) => {
  const usps = props.usps;
  const weather = props.weather;
  const allInfo = props.allInfo;
  const shipDate = allInfo.shipDate;

  const temps = props.temps;

  const year = shipDate.substring(0, 4);
  const month = shipDate.substring(4, 6);
  const day = shipDate.substring(6, 8);

  const stringDateFormatted = year + "-" + month + "-" + day;

  const [min, setMin] = useState(100);
  const [max, setMax] = useState(0);


  const checkWeather = () => {
    const shipDateObject = new Date(stringDateFormatted);
    const deliveryDateObject = new Date(usps.deliveryDate);

    const daysBetween = Math.ceil(
      (deliveryDateObject - shipDateObject) / 86400000
    );

    weather.map((day, i) => {
      if (i < daysBetween + 1) {
        if (day.temp.min < min) {
          setMin(day.temp.min);
        }
        if (day.temp.max > max) {
          setMax(day.temp.max);
        }
      }
    });
  };

  checkWeather();

  return (
    <div className="flex justify-between md:ml-4 my-2 md:my-0 items-center">
      <p className="text-slate-800 font-medium text-lg md:px-3">{usps.service}</p>
      <p className="text-slate-600 font-normal text-md px-3">
        {usps.deliveryDate}
      </p>
      <p className="text-slate-800 font-normal text-md px-3">
        High: {max}, Low: {min}
      </p>
      {min < temps.maxLow && (
        <h4 className="text-xl font-semibold text-red-800">Do Not Ship</h4>
      )}
      {min > temps.maxLow && min < temps.heatPack && (
        <h4 className="text-xl font-semibold text-yellow-600">Heatpack Needed</h4>
      )}
      {min < temps.insulationTemp && min > temps.heatPack && (
        <h4 className="text-xl font-semibold text-yellow-500">
          Insulation Needed
        </h4>
      )}
      {min > temps.insulationTemp && min>temps.heatPack && (
        <h4 className="text-xl font-semibold text-green-500">Safe To Ship</h4>
      )}
    </div>
  );
};

export default BulkResults;
