import { useEffect } from "react";

const Shopify = () => {
  useEffect(() => {
    window.gtag_report_conversion = function (url) {
      var callback = function () {
        if (typeof url !== "undefined") {
          window.location = url;
        }
      };
      window.gtag("event", "conversion", {
        send_to: "AW-11270483664/P9PFCKyWs8AYENDdl_4p",
        event_callback: callback,
      });
      return false;
    };
  }, []);

  return (
    <div className="border-2 rounded-lg border-slate-400 md:mt-0 mt-4">
      <h4 className="text-lg font-semibold text-slate-800 m-2  mx-3">
        🎉 WeatherIShip is now on{" "}
        <a
          onClick={() => window.gtag_report_conversion("https://apps.shopify.com/weatheriship")}
          href="https://apps.shopify.com/weatheriship"
          className="text-sky-900 font-bold cursor-pointer"
        >
          Shopify!
        </a>
      </h4>
    </div>
  );
};

export default Shopify;
