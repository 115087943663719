import usps from "../assets/usps.svg";
import { useEffect, useState } from "react";

const ShippingBoxUSPS = (props) => {
  const [min, setMin] = useState(100);
  const [max, setMax] = useState(0);

  const weather = props.weather;

  let service= props.service;

  if (props.service === "First-Class Mail"){
    service = "Ground Advantage"
  }

  const checkWeather = () => {
    const deliveryDate = new Date(props.expectedDelivery);

    const today = new Date();

    const daysBetween = Math.ceil((deliveryDate - today) / 86400000);

    weather.map((day, i) => {
      if (i < daysBetween + 1) {
        if (day.temp.min < min) {
          setMin(day.temp.min);
        }
        if (day.temp.max > max) {
          setMax(day.temp.max);
        }
      }
    });
  };

  checkWeather();

  return (
    <div className="bg-neutral-50 p-8 rounded-xl shadow-sm min-w-max ">
      <div className="flex content-center justify-center pb-3">
        <img src={usps} alt="ups logo" className="h-8 w-10 m-3" />
        <h1 className="text-2xl font-bold text-slate-700 m-auto">
          {service}
        </h1>
      </div>
      <h3 className="text-lg text-slate-700">
        Expected Delivery: {props.expectedDelivery}
      </h3>
      {min < props.low && (
        <h4 className="text-xl font-semibold text-red-800">Do Not Ship</h4>
      )}
      {min > props.high && (
        <h4 className="text-xl font-semibold text-green-500">Safe To Ship</h4>
      )}
      {/* {min < props.low -3 && min > props.low + 3 && (
        <h4 className="text-xl font-semibold text-green-500">Marginal</h4>
      )} */}

      {min < props.high && min > props.low && (
        <h4 className="text-xl font-semibold text-red-500">
          Insulation Needed
        </h4>
      )}
      <h5 className="text-md italic  text-slate-700">
        Low: {props.tempUnit ? <span>{min} °F</span> :<span>{Math.round((min - 32) * 5/9)} °C</span>} High: {props.tempUnit ? <span>{max} °F</span> :<span>{Math.round((max - 32) * 5/9)} °C</span>}
      </h5>
    </div>
  );
};

export default ShippingBoxUSPS;
