import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingReact from "../assets/LoadingReact";
import UserBar from "../Components/UserBar";
const SignupPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const email = e.target.email.value.toLowerCase();
    const password = e.target.password.value;
    const confirmpassword = e.target.confirmpassword.value;
    const ZIP = e.target.ZIP.value;

    if (password !== confirmpassword) {
      alert("Passwords do not match");
      setIsLoading(false)
      return;
    }

    const graphqlQuery = {
      query: `
                mutation {
                    createUser(userInput: {email: "${email}", password: "${password}", zip: "${ZIP}"}) {
                        _id
                    }
                }
                `,
    };

    fetch(`${process.env.REACT_APP_BACKENDSERVER}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Failed");
        }
        return res.json();
      })
      .then((resData) => {
        navigate("/login");
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <UserBar />
      <div className="flex font-sans flex-col justify-center items-center w-screen bg-slate-50 min-h-screen text-center">
        <div className="my-5">
          <h1 className="text-5xl font-bold text-slate-800">Create account</h1>
          <p className="text-md py-5 font-medium text-slate-700 ">
            Already have an account? {""}
            <Link to={"/login"} className="text-sky-600">
              Click here to sign in.
            </Link>
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col bg-white py-5 px-10 rounded-2xl"
        >
          {isLoading ? (
            <LoadingReact />
          ) : (
            <>
              {error.length > 0 && (
                <h3 className="text-red-700 font-semibold py-4">
                  Uh oh! Looks like your email is already in use!
                </h3>
              )}
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="email"
              >
                Email
              </label>
              <input
                required
                id="email"
                className="md:w-96 p-3 my-3 rounded-lg border"
                type="text"
                placeholder="Email address"
              />
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="ZIP "
              >
                Zip Code
              </label>
              <input
                required
                id="ZIP"
                className="w-full p-3 my-3 rounded-lg  border"
                maxLength={5}
                minLength={5}
                type="text"
                placeholder="Zip Code"
              />
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="password "
              >
                Password
              </label>
              <input
                required
                minLength={6}
                id="password"
                className="w-full p-3 my-3 rounded-lg  border"
                type="password"
                placeholder="Password"
              />
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="confirmpassword "
              >
                Confirm Password
              </label>
              <input
                required
                id="confirmpassword"
                className="w-full p-3 my-3 rounded-lg  border"
                type="password"
                placeholder="Confirm Password"
              />
              <button
                className="p-4 my-3 shadow-md bg-sky-500 text-slate-50 font-semibold rounded-lg hover:bg-sky-400"
                type="submit"
              >
                Register
              </button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default SignupPage;
