import HomePage from "./Pages/Homepage";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import SignupPage from "./Pages/SignupPage";
import Dashboard from "./Pages/Dashboard";
import { Provider } from "react-redux";
import store from "./app/store";
import Subscriptions from "./Pages/Subscriptions";
import SignUpPagePro from "./Pages/SignUpPagePro";
import Bulk from "./Pages/Bulk";
import Privacy from "./Pages/Privacy";
function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<SignupPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/registerpro" element={<SignUpPagePro />} />
        <Route path="/bulk-upload" element={<Bulk />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
    </Provider>
  );
}

export default App;
