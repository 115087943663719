import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserBar from "../Components/UserBar";
import useAuth from "../hooks/useAuth";
import { login } from "../app/profile";

import { logout } from "../app/profile";

const Dashboard = () => {
  useAuth();

  const userredux = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [temp, setTemp] = useState(false);

  const updateThreshold = async (e) => {
    e.preventDefault();
    const low = e.target[0].value;
    const high = e.target[1].value;

    const graphqlQuery = {
      query: `
            mutation {
                updateTemps(low: "${low}", high: "${high}") {
                    _id
                    low
                    high
                }
            }
        `,
    };

    const res = await fetch(`${process.env.REACT_APP_BACKENDSERVER}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },

      body: JSON.stringify(graphqlQuery),
    });

    const data = await res.json();

    if (data.data.updateTemps) {
      setTemp(true);
    }
  };

  const upgradeMembership = async () => {
    navigate("/subscriptions")
    // const res = await fetch(
    //   `${process.env.REACT_APP_BACKENDSERVER}/create-checkout-session`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //     body: JSON.stringify({
    //       priceId: "price_1Me258JwBvsDAd36LHVXFKD5",
    //     }),
    //   }
    // );

    // const data = await res.json();

    // window.location.href = data.url;
  };

  const editMembership = async (e) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKENDSERVER}/customer-portal`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );

    const response = await res.json();

    window.location.href = response.url;
  };

  const updateUser = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const zip = e.target.zip.value;

    const graphqlQuery = {
      query: `
            mutation {
                updateUser(email: "${email}", zip: "${zip}") {
                    _id
                    email
                    zip
                }

            }
        `,
    };

    const res = await fetch(`${process.env.REACT_APP_BACKENDSERVER}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(graphqlQuery),
    });

    const data = await res.json();

    if (data.data.updateUser) {
      setUserInfo(data.data.updateUser);
      setEditUser(false);
    }

    if (data.errors) {
      console.log(data.errors);
    }
  };

  const fetchUser = async () => {
    const graphqlQuery = {
      query: `
                query {
                    user {
                        _id
                        membership
                        zip
                        email
                        low
                        high
                        pro
                    }
                }
                `,
    };

    const res = await fetch(`${process.env.REACT_APP_BACKENDSERVER}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },

      body: JSON.stringify(graphqlQuery),
    });

    const data = await res.json();

    if (data.data.user) {
      setUserInfo(data.data.user);
      dispatch(
        login({
          zip: data.data.user.zip,
          low: data.data.user.low,
          high: data.data.user.high,
          authenticated: data.data.user.membership,
          pro: data.data.user.pro,
        })
      );
    }

    if (data.errors) {
      console.log(data.errors);
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("expiration");
      navigate("/login");
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("expiration");

    dispatch(logout());

    navigate("/login");
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const [editUser, setEditUser] = useState(false);

  const handleEditUser = () => {
    setEditUser(!editUser);
  };
  return (
    <>
      <UserBar />

      <div className="flex font-sans flex-col justify-center items-center w-screen bg-slate-50 min-h-screen text-center">
        <h1 className="text-5xl font-bold text-slate-800 px-10 py-5">
          Dashboard
        </h1>
        <div className=" flex flex-col bg-white px-10 pb-10 pt-5 rounded-xl items-center">
          {userInfo.membership && !userInfo.pro &&(
            <h3 className="pb-3 text-slate-600 text-xl font-semibold">
              Thanks for being a next member!
            </h3>
          )}
          {userInfo.pro && (
            <h3 className="pb-3 text-slate-600 text-xl font-semibold">
              Thanks for being a pro member!
            </h3>
          )}
          {editUser ? (
            <form
              onSubmit={updateUser}
              className="flex flex-col bg-white py-5 px-10 rounded-2xl"
            >
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="email"
              >
                Email
              </label>

              <input
                id="email"
                className="w-full p-3 my-3 rounded-lg border"
                type="text"
                placeholder="Email address"
                defaultValue={userInfo.email}
              />
              <label
                className="text-left font-semibold text-slate-800"
                htmlFor="ZIP "
              >
                Zip Code
              </label>
              <input
                id="zip"
                className="w-full p-3 my-3 rounded-lg  border"
                maxLength={5}
                minLength={5}
                type="text"
                placeholder="Zip Code"
                defaultValue={userInfo.zip}
              />

              <button
                className="p-4 my-3 shadow-md bg-sky-500 text-slate-50 font-semibold rounded-lg hover:bg-sky-400"
                type="submit"
              >
                Update
              </button>
              {/* <h3>Click here to reset password.</h3> */}
            </form>
          ) : (
            <button
              onClick={handleEditUser}
              className="py-4 px-10 my-3 shadow-md bg-sky-700 text-slate-50 font-semibold rounded-xl hover:bg-sky-600 w-64"
            >
              Edit account settings.
            </button>
          )}

          {userredux.authenticated && (
            <button
              onClick={editMembership}
              className="py-4 px-10 my-3 shadow-md bg-sky-700 text-slate-50 font-semibold rounded-xl hover:bg-sky-400 w-64"
            >
              Edit membership settings.
            </button>
          ) } 
          
          {!userredux.pro && (
            <button
              onClick={upgradeMembership}
              className="py-4 px-10 my-3 shadow-md bg-emerald-500 text-slate-50 font-semibold rounded-xl hover:bg-emerald-400 w-64"
            >
              Upgrade Membership
            </button>
          )}

          <button
            className="text-sky-900 font-semibold pt-2"
            onClick={logoutHandler}
          >
            Logout
          </button>

          {userInfo.membership ? (
            <>
              <form onSubmit={updateThreshold} className="pt-5 mt-5 border-t-2">
                {temp && (
                  <h3 className="text-green-800 font-semibold">
                    Temps Updated!
                  </h3>
                )}

                <h3 className="text-lg font-semibold p-2">Custom Thresholds</h3>
                <input
                  className="border rounded-3xl p-5  w-2/6 mr-5"
                  type="number"
                  placeholder="Do Not Ship Temp (F)"
                  required
                />
                <input
                  className="border rounded-3xl p-5  w-2/6 mr-5"
                  type="number"
                  placeholder="Insulation Temp (F)"
                  required
                />
                <button
                  className="p-4 my-3 shadow-md bg-sky-500 text-slate-50 font-semibold rounded-lg hover:bg-sky-400"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </>
          ) : (
            <form className="pt-5 mt-5 border-t-2">
              <h3 className="text-lg font-semibold p-2">
                Custom Thresholds (Pro Feature){" "}
              </h3>
              <input
                className="border rounded-3xl p-5  w-2/6 mr-5"
                type="number"
                id="low"
                placeholder="Min Temp (F)"
                required
                disabled
              />
              <input
                className="border rounded-3xl p-5  w-2/6 mr-5"
                id="high"
                type="number"
                placeholder="Max Temp (F)"
                required
                disabled
              />
              <button
                className="p-4 my-3 shadow-md bg-sky-500 text-slate-50 font-semibold rounded-lg hover:bg-sky-400"
                type="submit"
                disabled
              >
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
