import { useState } from "react";
import BulkWeather from "./BulkWeather";
const BulkGrid = (props) => {
  const [fetchedInfo, setFetchedInfo] = useState(props.fetchedInfo);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-max">
      {fetchedInfo
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(
          (item, i) => (
            (
              <div
                key={i}
                className="flex flex-col bg-white m-2 p-4 rounded-lg shadow-xs dark:bg-gray-800 w-screen md:w-full "
              >
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <h4 className=" text-slate-500 font-medium text-sm my-1 text-left">
                      Customer:
                    </h4>
                    <h2 className="text-slate-600 text-xl font-semibold">
                      {item.name}
                    </h2>
                  </div>
                  <div className="flex flex-col">
                    <h4 className=" text-slate-500 font-medium text-sm my-1 text-left">
                      Destination:
                    </h4>
                    <h2 className="text-slate-600 text-xl font-semibold">
                      {item.destination}
                    </h2>
                  </div>
                </div>

                <BulkWeather
                  usps={item}
                  temps={props.temps}
                  showHide={props.showHide}
                  weather={fetchedInfo[i].weather}
                />
              </div>
            )
          )
        )}
    </div>
  );
};

export default BulkGrid;
