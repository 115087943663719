import { useState } from "react";
import LoadingReact from "../assets/LoadingReact";
import BulkGrid from "../Components/BulkGrid";
import UserBar from "../Components/UserBar";
import useAuth from "../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Bulk = () => {
  useAuth();
  const navigate = useNavigate();
  const userredux = useSelector((state) => state.user);
  if (!userredux.pro) {
    navigate("/dashboard");
  }

  const [fileName, setFileName] = useState("Choose .CSV File");

  const [counter, setCounter] = useState(1);
  const [colHeaders, setColHeaders] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [fetchedInfo, setFetchedInfo] = useState([]);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const csvFile = e.target.csvUpload.files[0];
    if (!csvFile) {
      setLoading(false);
      return;
    }
    if (csvFile.type !== "text/csv") {
      setLoading(false);
      return;
    }
    if (csvFile.size > 2097152) {
      setLoading(false);
      return;
    }
    setFile(csvFile);
    const formData = new FormData();
    formData.append("csv", csvFile);
    fetch(`${process.env.REACT_APP_BACKENDSERVER}/csvdata`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Failed");
        }
        return res.json();
      })
      .then((resData) => {
        setCounter(counter + 1);
        setColHeaders(resData.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const submitWithCols = (e) => {
    setLoading(true);
    e.preventDefault();
    const zipCodeCol = e.target.zipCodeCol.value;
    const nameCol = e.target.nameCol.value;
    const originZip = e.target.originZip.value;
    const date = e.target.date.value;
    const formData = new FormData();
    formData.append("csv", file);
    formData.append("zipCodeCol", zipCodeCol);
    formData.append("nameCol", nameCol);
    formData.append("originZip", originZip);
    formData.append("date", date);
    fetch(`${process.env.REACT_APP_BACKENDSERVER}/csvfetchdata`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Failed");
        }
        return res.json();
      })
      .then((resData) => {
        setFetchedInfo(resData.data);
        setLoading(false);
        setCounter(counter + 1);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [showHide, setShowHide] = useState("both");

  const [temps, setTemps] = useState({
    maxLow: 32,
    insulationTemp: 40,
    heatPack: 50,
  });

  const tempUpdate = (e) => {
    e.preventDefault();

    const maxLow = e.target.maxlow.value;
    const insulationTemp = e.target.insulationonly.value;
    const heatPack = e.target.heatpack.value;
    const showHide = e.target.showhide.value;

    setShowHide(showHide);

    setTemps({
      maxLow: maxLow,
      insulationTemp: insulationTemp,
      heatPack: heatPack,
    });
  };

  return (
    <>
      <UserBar />
      <div className="flex font-sans flex-col justify-center items-center w-screen bg-slate-50 min-h-screen text-center">
        <h1 className="text-5xl font-bold text-slate-800 px-10 py-3 mt-5">
          Bulk Upload
        </h1>
        <p className="text-md py-3 font-medium text-slate-700 md:w-1/4 w-2/3 ">
          Please make sure the CSV file is formatted correctly and that a column
          for destination zip exists.
        </p>
        {counter === 3 && (
          <form
            className="p-6 my-8 bg-white flex md:flex-row flex-col w-5/6 w-max justify-center"
            onSubmit={tempUpdate}
          >
            <div className="flex flex-col mr-10 items-center">
              <label
                htmlFor="maxlow"
                className=" text-slate-500 font-medium text-sm my-1 "
              >
                Max Low Temp (F)
              </label>
              <input
                name="maxlow"
                id="maxlow"
                defaultValue={"32"}
                className="text-center font-semibold text-slate-800  border-2 p-4 hover:text-slate-900 hover:border-slate-500 cursor-pointer md:w-28"
              />
            </div>
            <div className="flex flex-col mr-10 items-center">
              <label
                htmlFor="heatpack"
                className=" text-slate-500 font-medium text-sm my-1 "
              >
                Heatpack + Insulation Temp (F)
              </label>
              <input
                name="heatpack"
                id="heatpack"
                defaultValue={"40"}
                className="text-center font-semibold text-slate-800  border-2 p-4 hover:text-slate-900 hover:border-slate-500 cursor-pointer md:w-28"
              />
            </div>
            <div className="flex flex-col mr-10 items-center">
              <label
                htmlFor="insulationonly"
                className=" text-slate-500 font-medium text-sm my-1 "
              >
                Insulation Only Temp (F)
              </label>
              <input
                name="insulationonly"
                id="insulationonly"
                defaultValue={"50"}
                className="text-center font-semibold text-slate-800  border-2 p-4 hover:text-slate-900 hover:border-slate-500 cursor-pointer md:w-28"
              />
            </div>
            <div className="flex flex-col mr-10 items-center">
              <label
                htmlFor="showhide"
                className=" text-slate-500 font-medium text-sm my-1 "
              >
                Show/Hide Data
              </label>
              <select
                name="showhide"
                id="showhide"
                className="text-left  max-w-md font-semibold text-slate-800  border-2 p-4 hover:text-slate-900 hover:border-slate-700 cursor-pointer"
              >
                <option defaultChecked value={"both"}>
                  UPS+USPS
                </option>
                <option value={"ups"}>UPS only</option>
                <option value={"usps"}>USPS only</option>
              </select>
            </div>
            <div className="flex flex-col mr-10">
              <label className=" text-white font-medium text-sm my-1 text-left">
                Submit
              </label>
              <button
                type="submit"
                className="p-4 m-2 md:m-0 shadow-md bg-sky-500 text-slate-50 font-semibold rounded-lg hover:bg-sky-400"
              >
                Update
              </button>
            </div>
          </form>
        )}
        {loading ? (
          <LoadingReact />
        ) : (
          <>
            {counter === 1 && (
              <form
                encType="multipart/form-data"
                onSubmit={submitHandler}
                className="p-6 my-8 bg-white"
              >
                <label
                  className="text-left font-semibold text-slate-800 mr-10 border-2 p-4 hover:text-slate-900 hover:border-slate-700 cursor-pointer"
                  htmlFor="csvUpload"
                >
                  {fileName}
                </label>
                <input
                  type="file"
                  hidden
                  id="csvUpload"
                  accept=".csv"
                  name="csvUpload"
                  onChange={(e) => {
                    setFileName(e.target.files[0].name);
                  }}
                />

                <button
                  type="submit"
                  className="p-4 m-2 md:m-0 shadow-md bg-sky-500 text-slate-50 font-semibold rounded-lg hover:bg-sky-400"
                >
                  Upload
                </button>
              </form>
            )}
            {counter === 2 && (
              <form
                className="p-5 my-8 bg-white flex  md:flex-row md:w-max flex-col w-5/6"
                onSubmit={submitWithCols}
              >
                <div className="flex flex-col mr-10">
                  <label
                    htmlFor="zipCodeCol"
                    className=" text-slate-500 font-medium text-sm my-1 text-left"
                  >
                    Zip Column
                  </label>

                  <select
                    name="zipCodeCol"
                    required
                    id="zipCodeCol"
                    className="text-left max-w-sm font-semibold text-slate-800  border-2 p-4 hover:text-slate-900 hover:border-slate-700 cursor-pointer"
                  >
                    <option key={200}>---</option>
                    {colHeaders.map((col, index) => (
                      <option key={index} value={col}>
                        {col}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col mr-10">
                  <label
                    htmlFor="nameCol"
                    className=" text-slate-500 font-medium text-sm my-1 text-left "
                  >
                    Customer Column
                  </label>
                  <select
                    name="nameCol"
                    required
                    id="nameCol"
                    className=" max-w-sm text-left font-semibold text-slate-800  border-2 p-4 hover:text-slate-900 hover:border-slate-700 cursor-pointer"
                  >
                    <option key={200}>---</option>

                    {colHeaders.map((col, index) => (
                      <option key={index} value={col}>
                        {col}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col mr-10">
                  <label
                    htmlFor="originZip"
                    className=" text-slate-500 font-medium text-sm my-1 text-left"
                  >
                    Origin Zip Code
                  </label>
                  <input
                    name="originZip"
                    id="originZip"
                    required
                    defaultValue={"32952"}
                    className="text-left font-semibold text-slate-800  border-2 p-4 hover:text-slate-900 hover:border-slate-500 cursor-pointer"
                  />
                </div>
                <div className="flex flex-col mr-10">
                  <label
                    htmlFor="date"
                    className=" text-slate-500 font-medium text-sm my-1 text-left"
                  >
                    Ship Date
                  </label>
                  <input
                    name="date"
                    id="date"
                    type={"date"}
                    required
                    defaultValue={new Date().toISOString().split("T")[0]}
                    min={new Date().toISOString().split("T")[0]}
                    max={
                      new Date(new Date().setDate(new Date().getDate() + 4))
                        .toISOString()
                        .split("T")[0]
                    }
                    className="text-left font-semibold text-slate-800  border-2 p-4 hover:text-slate-900 hover:border-slate-500 cursor-pointer"
                  />
                </div>

                <div className="flex flex-col mr-10">
                  <label className=" text-white font-medium text-sm my-1 text-left">
                    Submit
                  </label>
                  <button
                    type="submit"
                    className="p-4 md:m-0 shadow-md bg-sky-500 text-slate-50 font-semibold rounded-lg hover:bg-sky-400"
                  >
                    Select
                  </button>
                </div>
              </form>
            )}
            {counter === 3 && (
              <BulkGrid
                fetchedInfo={fetchedInfo}
                temps={temps}
                showHide={showHide}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Bulk;
