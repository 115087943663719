import { useEffect, useState } from "react";
import BulkResults from "./BulkResults";
import uspslogo from "../assets/uspslong.svg";
import BulkResultsUPS from "./BulkResultsUPS";
import upslogo from "../assets/ups.svg";

const BulkWeather = (props) => {
  const [usps, setUsps] = useState(props.usps.usps);
  const [ups, setUps] = useState()

  const trimUPS = () => {
    let upsTrimmed = [];
    for (let i = 0; i < props.usps.ups.ServiceSummary.length; i++) {
      if (props.usps.ups.ServiceSummary[i].Service.Code === "1DA") {
        upsTrimmed.push(props.usps.ups.ServiceSummary[i]);
      } else if (props.usps.ups.ServiceSummary[i].Service.Code === "GND") {
        upsTrimmed.push(props.usps.ups.ServiceSummary[i]);
      } else if (props.usps.ups.ServiceSummary[i].Service.Code === "2DA") {
        upsTrimmed.push(props.usps.ups.ServiceSummary[i]);
      } else if (props.usps.ups.ServiceSummary[i].Service.Code === "3DS") {
        upsTrimmed.push(props.usps.ups.ServiceSummary[i]);
      }
    }

    setUps(upsTrimmed);
  };

  useEffect(() => {
    trimUPS();
  }, []);

  const [weather, setWeather] = useState(props.weather);

  return (
    <>
      {Array.isArray(ups) && (
        <>
          <div className="flex-col">
            {weather.length > 1 &&
              (props.showHide === "usps" || props.showHide === "both") && (
                <img className="w-28 my-4" src={uspslogo} alt="usps logo" />
              )}
            {weather.length > 1 &&
              (props.showHide === "usps" || props.showHide === "both") &&
              usps.map((item, i) => {
                return (
                  <BulkResults
                    usps={item}
                    weather={weather}
                    allInfo={props.usps}
                    temps={props.temps}
                    key={i}
                  />
                );
              })}
          </div>
          <div className="flex-col">
            {weather.length > 1 &&
              ups.length > 2 &&
              (props.showHide === "ups" || props.showHide === "both") && (
                <img className="w-8 my-4" src={upslogo} alt="ups logo" />
              )}
            {weather.length > 1 &&
              ups.length > 2 &&
              (props.showHide === "ups" || props.showHide === "both") &&
              ups.map((item, i) => {
                return (
                  <BulkResultsUPS
                    ups={item}
                    weather={weather}
                    allInfo={props.usps}
                    temps={props.temps}
                    key={i}
                  />
                );
              })}
          </div>
        </>
      )}
    </>
  );
};
export default BulkWeather;

// useEffect(() => {
//   weatherHandler();
// }, []);

// const weatherHandler = async () => {
//   const fetched = await fetch(
//     `${process.env.REACT_APP_BACKENDSERVER}/fetch`,
//     {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         origin: origin,
//         destination: props.usps.destination,
//       }),
//     }
//   );

//   const data = await fetched.json();
//   setWeather(data.data);
// };
