import UserBar from "../Components/UserBar";
import check from "../assets/check.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Subscriptions = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const signUpHandler = () => {
    navigate("/register");
  };

  const upgradeMembership = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKENDSERVER}/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          priceId: "price_1Me258JwBvsDAd36LHVXFKD5",
        }),
      }
    );

    const data = await res.json();

    window.location.href = data.url;
  };

  const upgradeMembershipPro = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKENDSERVER}/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          priceId: "price_1MjAVmJwBvsDAd366DMavBgu",
        }),
      }
    );

    const data = await res.json();

    window.location.href = data.url;
  };

  const upgradeHandler = () => {
    if (user.zip) {
      upgradeMembership();
    } else {
      navigate("/registerpro");
    }
  };

  const upgradeHandlerPro = () => {
    if (user.zip) {
      upgradeMembershipPro();
    } else {
      navigate("/register");
    }
  };

  return (
    <>
      <UserBar />
      <div className="flex font-sans flex-col justify-center items-center w-screen bg-slate-50 min-h-screen text-center">
        <h1 className="text-5xl font-bold text-slate-800 pt-10 md:pt-0">
          Subscriptions
        </h1>
        <h5 className="text-md py-3 font-medium text-slate-700  ">
          Upgrade your experience with different memberships today!
        </h5>
        <div className="bg-white flex rounded-3xl mt-8 md:flex-row flex-col">
          <div className="p-10 border-r-2 border-slate-100 w-screen md:w-96">
            <h2 className="text-md py-2 font-medium text-slate-900">Basic</h2>
            <h3 className=" text-md font-medium text-slate-800">
              <span className="text-4xl font-bold text-slate-800 text-left">
                FREE
              </span>
              /forever
            </h3>
            {user.zip ? (
              <button
                disabled
                className="py-3 m-5  mt-8 px-10  disabled:opacity-75 shadow-md bg-indigo-600 text-slate-50 text-md font-semibold rounded-lg "
              >
                Already a member!
              </button>
            ) : (
              <button
                onClick={signUpHandler}
                className="py-3 m-5  mt-8 px-10 shadow-md bg-indigo-600 text-slate-50 text-md font-semibold rounded-lg hover:bg-sky-600"
              >
                Join today!
              </button>
            )}
            <h3 className="text-md py-2 font-medium text-slate-600 ">
              Perfect for the occasional shipper.
            </h3>
            <ul className="text-left px-4">
              <li className="text-slate-600  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">Remember zip code</p>
                </div>
              </li>
              <li className="text-slate-600  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">Access to USPS shipping data</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="p-10  w-screen md:w-96 relative">
            <h2 className="text-md py-2 font-medium text-slate-900 ">Next</h2>
            <h3 className=" text-md font-medium text-slate-800">
              <span className="text-4xl font-bold text-slate-800 text-left">
                $10
              </span>
              /month
            </h3>
            <h4 className="text-slate-700 text-sm absolute text-center left-0 right-0 mr-auto ml-auto">
              $8 per month if paid annually
            </h4>
            {user.authenticated ? (
              <button
                disabled
                className="py-3 m-5  mt-8 px-10  disabled:opacity-75 shadow-md bg-indigo-600 text-slate-50 text-md font-semibold rounded-lg "
              >
                Already a member!
              </button>
            ) : (
              <button
                onClick={upgradeHandler}
                className="py-3 m-5  mt-8 px-10 shadow-md bg-indigo-600 text-slate-50 text-md font-semibold rounded-lg hover:bg-sky-600"
              >
                Upgrade to Next!
              </button>
            )}
            <h3 className="text-md py-2 font-medium text-slate-600">
              Perfect for new businesses and those who use UPS.
            </h3>
            <ul className="text-left px-4">
              <li className="text-slate-700  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">
                    Everything in Basic Plan plus...
                  </p>
                </div>
              </li>
              <li className="text-slate-700  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">UPS shipping data</p>
                </div>
              </li>
              <li className="text-slate-700  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">
                    Choose ship day, up to three days in advance
                  </p>
                </div>
              </li>
              <li className="text-slate-700  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">Choose your own thresholds</p>
                </div>
              </li>

              <li className="text-slate-600  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">
                    See weather up to 7 days in the future
                  </p>
                </div>
              </li>
            </ul>
          </div>
          {/* pro membership */}
          <div className="p-10  border-l-2 border-slate-100 w-screen md:w-96 relative">
            <h2 className="text-md py-2 font-medium text-slate-900 ">Pro</h2>
            <h3 className=" text-md font-medium text-slate-800">
              <span className="text-4xl font-bold text-slate-800 text-left">
                $25
              </span>
              /month
            </h3>

            {user.pro ? (
              <button
                disabled
                className="py-3 m-5  mt-8 px-10  disabled:opacity-75 shadow-md bg-indigo-600 text-slate-50 text-md font-semibold rounded-lg "
              >
                Already a member!
              </button>
            ) : (
              <button
                onClick={upgradeHandlerPro}
                className="py-3 m-5  mt-8 px-10 shadow-md bg-indigo-600 text-slate-50 text-md font-semibold rounded-lg hover:bg-sky-600"
              >
                Upgrade to Pro!
              </button>
            )}
            <h3 className="text-md py-2 font-medium text-slate-600">
              Perfect for businesses who ship several times a week.
            </h3>
            <ul className="text-left px-4">
              <li className="text-slate-700  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">Everything in Next Plan plus...</p>
                </div>
              </li>
              <li className="text-slate-700  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">Bulk Reports</p>
                </div>
              </li>
              <li className="text-slate-700  my-3 ">
                <div className="flex items-center">
                  <img src={check} alt="check" className="inline pr-2 h-7 " />
                  <p className="font-medium">
                    Easily upload .csv files to generate reports for every USPS
                    and UPS service
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Subscriptions;
