import { useEffect, useState } from "react";
import LoadingReact from "../assets/LoadingReact";
import Forecast from "../Components/Forecast";
import Help from "../Components/Help";
import Results from "../Components/Results";
import UserBar from "../Components/UserBar";
import Shopify from "../Components/Shopify";

const HomePage = () => {
  const [weather, setWeather] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const [tempUnit, setTempUnit] = useState(true);

  const tempUnitHandler = () => {
    setTempUnit(!tempUnit);
  };

  const today = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = today.getDate();
  const month = months[today.getMonth()];
  const year = today.getFullYear();
  const final = `${date}-${month}-${year}`;

  const [userInfo, setUserInfo] = useState({
    membership: false,
  });

  const [temps, setTemps] = useState({
    low: "32",
    high: "42",
  });

  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [usps, setUSPS] = useState({});
  const [ups, setUPS] = useState([]);
  const [ship, setShip] = useState(final);

  const fetchUser = async () => {
    const graphqlQuery = {
      query: `
                query {
                    user {
                        _id
                        membership
                        zip
                        email
                        low
                        high
                    }
                }
                `,
    };

    const res = await fetch(`${process.env.REACT_APP_BACKENDSERVER}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },

      body: JSON.stringify(graphqlQuery),
    });

    const data = await res.json();

    if (data.data.user) {
      setUserInfo(data.data.user);
      setOrigin(parseInt(data.data.user.zip));
      setTemps({
        low: data.data.user.low,
        high: data.data.user.high,
      });
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("token") ||
      localStorage.getItem("expiration") > Date.now()
    ) {
      fetchUser();
    }
  }, []);

  const USPSFetch = async () => {
    setUSPS({});
    const fetched = await fetch(
      `${process.env.REACT_APP_BACKENDSERVER}/getusps`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id,
          origin: origin,
          destination: destination,
        }),
      }
    ); // fetch the data from the server

    const data = await fetched.json();
    setUSPS(data.data);
    if (data) {
      setIsLoading(false);
    }
  };

  const USPSFetchPremium = async () => {
    setUSPS({});
    const fetched = await fetch(
      `${process.env.REACT_APP_BACKENDSERVER}/getUSPSPremium`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id,
          origin: origin,
          destination: destination,
          ship: ship,
        }),
      }
    ); // fetch the data from the server

    const data = await fetched.json();
    setUSPS(data.data);
    if (data) {
      setIsLoading(false);
    }
  };

  const UPSFetch = async () => {
    const originString = origin.toString();

    let shipFormatted = ship.split("-");
    shipFormatted[1] = months.indexOf(shipFormatted[1]) + 1;
    shipFormatted[1] = shipFormatted[1].toString();

    if (shipFormatted[0].length === 1) {
      shipFormatted[0] = "0" + shipFormatted[0];
    }
    if (shipFormatted[1].length === 1) {
      shipFormatted[1] = "0" + shipFormatted[1];
    }

    shipFormatted = shipFormatted.reverse().join("");

    const fetched = await fetch(
      `${process.env.REACT_APP_BACKENDSERVER}/getUPS`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id,
          origin: originString,
          destination: destination,
          ship: shipFormatted,
        }),
      }
    ); // fetch the data from the server

    const data = await fetched.json();
    if (data.data.TimeInTransitResponse) {
      setUPS(data.data.TimeInTransitResponse.TransitResponse.ServiceSummary);
    } else {
      throw new Error("UPS Error");
    }
  };

  const weatherHandler = async () => {
    const fetched = await fetch(
      `${process.env.REACT_APP_BACKENDSERVER}/fetch`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          origin: origin,
          destination: destination,
        }),
      }
    );

    const data = await fetched.json();
    setWeather(data.data);
  };
  const fetchDataHandler = async (e) => {
    try {
      setWeather([]);
      e.preventDefault();
      setIsLoading(true);
      USPSFetch();
      weatherHandler();
    } catch (err) {
      console.log("error");
    }
  };

  const fetchDataPremiumHandler = async (e) => {
    try {
      setWeather([]);
      e.preventDefault();
      setIsLoading(true);
      USPSFetchPremium();
      UPSFetch();
      weatherHandler();
    } catch (err) {
      console.log(err.message);
      console.log("error");
    }
  };

  const originHandler = (e) => {
    setOrigin(e.target.value);
  };

  const shipDateHandler = (e) => {
    const today = new Date();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    today.setDate(today.getDate() + parseInt(e.target.value));
    const date = today.getDate();
    const month = months[today.getMonth()];
    const year = today.getFullYear();
    const final = `${date}-${month}-${year}`;

    setShip(final);
  };

  const destinationHandler = (e) => {
    setDestination(e.target.value);
  };

  return (
    <>
      <UserBar />
      <div className="flex font-sans flex-col justify-center items-center w-full  min-h-screen text-center ">
        <Shopify/>
        <h1 className="text-5xl md:text-6xl font-bold text-sky-950 p-2 md:mt-4 md:mb-2 mt-3 mb-2">
          Welcome to WeatherIShip
        </h1>
        <h3 className="text-lg md:text-xl font-normal text-slate-800 px-4 md:px-0 md:w-2/4">
          Easily see if your temperature-sensitive products are safe to ship. Enter your destination/origin zip codes to get started.
        </h3>
        {!userInfo.membership ? (
          <form
            onSubmit={fetchDataHandler}
            className="flex  justify-center p-10 flex-col md:flex-row md:space-x-2"
          >
            <input
              name="shipDate"
              id="shipDate"
              onChange={shipDateHandler}
              disabled
              className="p-4 m-2 md:m-0 disabled:border-slate-300 disabled:border-2"
              placeholder="Ship Date"
            />
            <input
              type="text"
              className="p-4 m-2 md:m-0 border"
              placeholder="Origin Zip Code"
              onChange={originHandler}
              minLength="5"
              maxLength="5"
              required
              defaultValue={origin}
            />
            <input
              type="text"
              className="p-4 m-2 md:m-0 border"
              placeholder="Destination Zip Code"
              onChange={destinationHandler}
              minLength="5"
              required
              maxLength="5"
            />
            <button
              type="submit"
              className="p-4 m-2 md:m-0 shadow-md bg-sky-700 text-slate-50 font-semibold rounded-lg hover:bg-sky-600"
            >
              Search
            </button>
          </form>
        ) : (
          <form
            onSubmit={fetchDataPremiumHandler}
            className="flex  justify-center p-10 flex-col md:flex-row md:space-x-2"
          >
            <select
              name="shipDate"
              id="shipDate"
              onChange={shipDateHandler}
              className="p-4 m-2 md:m-0 disabled:border-slate-300 disabled:border-2"
            >
              <option value="0">Today</option>
              <option value="1">Tomorrow</option>
              <option value="2">Day After Tomorrow</option>
            </select>
            <input
              type="text"
              className="p-4 m-2 md:m-0"
              placeholder="Origin Zip Code"
              onChange={originHandler}
              minLength="5"
              maxLength="5"
              required
              defaultValue={origin}
            />
            <input
              type="text"
              className="p-4 m-2 md:m-0"
              placeholder="Destination Zip Code"
              onChange={destinationHandler}
              minLength="5"
              required
              maxLength="5"
            />
            <button
              type="submit"
              className="p-4 m-2 md:m-0 shadow-md bg-sky-700 text-slate-50 font-semibold rounded-lg hover:bg-sky-600"
            >
              Search
            </button>
          </form>
        )}
        {loading && <LoadingReact />}

        {weather && usps && weather.length > 0 && usps.length > 0 && (
          <Forecast
            weather={weather}
            services={usps}
            user={userInfo}
            ups={ups}
            tempUnit={tempUnit}
          />
        )}
        {weather && usps && ups && usps.length > 0 && weather.length > 0 && (
          <Results
            services={usps}
            ups={ups}
            weather={weather}
            low={temps.low}
            high={temps.high}
            tempUnit={tempUnit}
          />
        )}
        <div className="flex md:block">
          <Help />
          <div
            onClick={tempUnitHandler}
            className="md:fixed  h-16 bg-sky-600 min-width-max rounded-3xl flex justify-center items-center md:bottom-10 md:left-10 hover:bg-sky-500 cursor-pointer transition transition-all duration-300 m-5"
          >
            {!tempUnit && (
              <p className="mx-5 text-slate-100 text-2xl font-semibold tracking-tighter mb-1">
                F °
              </p>
            )}
            {tempUnit && (
              <p className="mx-5 text-slate-100 text-2xl font-semibold tracking-tighter mb-1">
                C °
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
